import React, { useState } from 'react';
import './styles.css';
import { CheckIcon } from "@heroicons/react/16/solid";

interface CustomCheckboxProps {
    id: string;
    label: React.ReactNode;  // Pour accepter plusieurs éléments dans le label
    checked?: boolean;
    onChange?: (checked: boolean) => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ id, label, checked = false, onChange }) => {
    const [isChecked, setIsChecked] = useState(checked);

    const handleChange = () => {
        const newCheckedState = !isChecked;
        setIsChecked(newCheckedState);
        if (onChange) onChange(newCheckedState);
    };

    return (
        <label htmlFor={id} className="flex items-center cursor-pointer">
            <input
                id={id}
                type="checkbox"
                checked={isChecked}
                className="hidden"  // Cacher l'input natif
            />
            <div
                onClick={handleChange}
                className={`w-checkbox w-5 h-5 rounded flex items-center justify-center
        ${isChecked ? 'w-checkbox-check' : ''} transition-colors duration-200`}
            >
                {isChecked && (
                    <CheckIcon color="white"/>
                )}
            </div>
            <span className="ml-2 text-black underline text-sm">{label}</span>
        </label>
    );
};

export default CustomCheckbox;
