import React from 'react';
import "./styles.css";
import WInput from "../../../components/WInput";
import WButton from "../../../components/Wbutton";
import {useTranslation} from "react-i18next";
import WCheckbox from "../../../components/WCheckbox";

const GuestRegistration: React.FC = () => {

    const {t} = useTranslation();

    return (
        <div className="flex flex-col w-svw">
            <span className="font-bold text-lg mt-8">BIENVENUE Christophe !</span>
            <span className="font-normal">
                Vous avez été invité par Valentino pour le projet Gecko
            </span>

            <span className="font-bold text-green-600 text-lg mt-8">Créez votre mot de passe pour pouvoir accéder à votre compte </span>
            <span className="font-normal">
                Votre mot de passe doit  être composé d’au moins 8 caractères
            </span>

            <div className="flex flex-col form-container">
                {/* User */}
                <div className="flex gap-6">
                    <WInput placeholder={t('ph-firstname')} name="firstname" required/>
                    <WInput placeholder={t('ph-lastname')} name="lastname" required/>
                </div>

                {/* Password */}
                <div className="flex gap-6 mt-12">
                    <WInput placeholder={t('ph-password')}
                            name="password"
                            type="password"
                            required/>
                </div>
                <div className="flex gap-6 mt-6">
                    <WInput placeholder={t('ph-confirm-password')}
                            name="confirm-password"
                            type="password"
                            required
                    />
                </div>

                <div className="flex gap-6 mt-12">
                    <WCheckbox id="dded"
                               label={<>Accepter les conditions générales<span className="text-red-500">*</span></>}/>
                </div>

                <div className="flex m-auto mt-12">
                    <WButton variant="secondary">
                        Enregistrer et me connecter
                    </WButton>
                </div>
            </div>

            <div className="flex justify-center space-x-4 mt-3">
                <a href="/login" className="text-gray-400 text-sm font-medium underline mr-10">J’ai déjà un compte</a>
                <a href="/login" className="text-green-600 text-sm font-medium underline ml-10">Je me connecte</a>
            </div>

        </div>);
};

export default GuestRegistration;