import React, {useState, FC} from 'react';
import './styles.css';
import {IWInputProps} from "./types";


const WInput: FC<IWInputProps> = ({name, placeholder = "Nom", required = false, type = "text", ...props}) => {
    const [focused, setFocused] = useState(false);
    const [value, setValue] = useState('');

    return (
        <div className={`flex flex-grow floating-label-container ${focused || value ? 'focused' : ''}`}>
            <label htmlFor={name} className="floating-label">
                {placeholder} {required && <span className="text-red-500">*</span>}
            </label>
            <input
                type={type}
                id={name}
                name={name}
                className="flex flex-grow floating-input"
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onChange={(e) => setValue(e.target.value)}
                value={value}
                required={required}
                {...props}
            />
        </div>
    );
};

export default WInput;
