import React from 'react';
import {useTranslation} from "react-i18next";
import WPrice from "../../../components/WPrice";
import WSwitch from "../../../components/WSwitch";
import './styles.css';
import WButton from "../../../components/Wbutton";

const PricingPlans: React.FC = () => {
    const {t} = useTranslation();

    return (
        <div className="flex flex-col w-svw">
            <span className="font-bold text-lg mt-3">{t('welcome')}</span>
            <span className="font-normal">{t('subscription')}</span>

            <div className="abo">
                <span className="period">Mensuel</span>
                <WSwitch/>
                <span className="period"><span>Annuel</span><span className="reduc">(-20%)</span></span>
            </div>

            <div className="flex flex-col md:flex-row md:justify-center">
                <WPrice title="Découverte"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                        users="1"
                        guests="illimités"
                        projects="1"
                        price={49}
                />
                <WPrice title="Agence"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                        users="10"
                        guests="illimités"
                        projects="100"
                        price={99}
                />
                <WPrice title="Corpo"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                        users="100"
                        guests="illimités"
                        projects="illimités"
                        price={199}
                />
            </div>

            <div>
                <WButton className="focus:ring-gray-600 hover:bg-gray-700 mt-6">Revenir au site Web</WButton>
            </div>
        </div>
    );
};

export default PricingPlans;
