import React from 'react';
import "./styles.css";
import {useTranslation} from "react-i18next";
import WInput from "../../../components/WInput";
import WButton from "../../../components/Wbutton";
import {ChevronLeftIcon} from '@heroicons/react/16/solid';
import {ChevronRightIcon} from "@heroicons/react/16/solid";
import WCheckbox from "../../../components/WCheckbox";
import WInfo from "../../../components/WInfo";
import {useNavigate} from "react-router-dom";

const PaymentForm: React.FC = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        navigate('/confirm-payment');
    };

    return (
        <div className="flex flex-col w-svw">
            <span className="font-bold text-lg mt-3">{t('title-form')}</span>
            <span className="font-normal">{t('details')}</span>

            <WInfo>Votre choix : Pack Découverte - Abonnement mensuel 49€ /moiss</WInfo>

            <div className="flex flex-col m-auto form-container">

                {/* User */}
                <div className="flex gap-6">
                    <WInput placeholder={t('ph-firstname')} name="firstname" required/>
                    <WInput placeholder={t('ph-lastname')} name="lastname" required/>
                </div>
                <div className="flex gap-6 mt-6">
                    <WInput placeholder={t('ph-email')} name="email" required/>
                    <WInput placeholder={t('ph-phone')} name="phone" required/>
                </div>

                {/* Company */}
                <div className="flex gap-6 mt-6">
                    <WInput placeholder={t('ph-company')} name="company" required/>
                    <WInput placeholder={t('ph-siret')} name="siret" required/>
                </div>
                <div className="flex gap-6 mt-12">
                    <WInput placeholder={t('ph-addr')} name="addr" required/>
                </div>
                <div className="flex gap-6 mt-6">
                    <WInput placeholder={t('ph-city')} name="city" required/>
                    <WInput placeholder={t('ph-postal')} name="postal" required/>
                </div>

                {/* Password */}
                <div className="flex gap-6 mt-12">
                    <WInput placeholder={t('ph-password')}
                            name="password"
                            type="password"
                            required/>
                </div>
                <div className="flex gap-6 mt-6">
                    <WInput placeholder={t('ph-confirm-password')}
                            name="confirm-password"
                            type="password"
                            required
                    />
                </div>


                <div className="flex gap-6 mt-12">
                    <WCheckbox id="dded"
                               label={<>Accepter les conditions générales<span className="text-red-500">*</span></>}/>
                </div>

                <div className="flex justify-between gap-6 mt-16">
                    <WButton variant="primary" className="btn-form" onClick={() => navigate('/pricing-plans')}>
                        <ChevronLeftIcon/>{"Précédent"}
                    </WButton>
                    <WButton variant="secondary" className="btn-form" onClick={handleSubmit}>
                        {"Payer"}<ChevronRightIcon/>
                    </WButton>
                </div>
            </div>
        </div>);
};

export default PaymentForm;