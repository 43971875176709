import React from 'react';
import './styles.css';
import WTooltip from "../WTooltip";
import {IWPriceProps} from "./types";
import WButton from "../Wbutton";
import {useNavigate} from "react-router-dom";

const WPrice: React.FC<IWPriceProps> = ({title,description, users, guests, projects,price}) => {
    const navigate = useNavigate();
    return (
        <div className="price-container">
            <h1 className="price-title">{title}</h1>
            <p className="price-description">{description}</p>

            <div className="price-container-inner">
                <span className="price-trait"></span>
                <div>
                    <span className="price-info">Utilisateurs :</span>
                    <span className="price-info-value">{users}</span>
                    <span className="price-info-bulle"
                          data-tooltip-id="tooltip"
                          data-tooltip-content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                          data-tooltip-place="top"
                    >?</span>
                    <WTooltip id="tooltip" />
                </div>

                <span className="price-trait"></span>
                <div>
                    <span className="price-info">Invités :</span>
                    <span className="price-info-value">{guests}</span>
                    <span className="price-info-bulle"
                          data-tooltip-id="tooltip"
                          data-tooltip-content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                          data-tooltip-place="top"
                    >?</span>
                    <WTooltip id="tooltip" />
                </div>

                <span className="price-trait"></span>
                <div>
                    <span className="price-info">Projects :</span>
                    <span className="price-info-value">{projects}</span>
                    <span className="price-info-bulle"
                          data-tooltip-id="tooltip"
                          data-tooltip-content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                          data-tooltip-place="top"
                    >?</span>
                    <WTooltip id="tooltip" />
                </div>

                <span className="price-trait"></span>
            </div>

            <div className="price-price">{price} €<span className="price-tva">htva</span></div>

            <WButton onClick={() => { navigate('/payment-form') }} variant="secondary" className="mt-6" >Commencer</WButton>

        </div>
    );
};

export default WPrice;