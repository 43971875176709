import React from 'react';
import {Outlet} from 'react-router-dom';
import {ReactComponent as Logo} from '../assets/images/logo.svg';

interface IMainLayoutProps {
    hasBanner?: boolean;
}

const MainLayout: React.FC<IMainLayoutProps> = ({hasBanner = false}) => {
    return (
        <div className="flex flex-col min-h-screen">
            <main className="flex flex-col h-svh">
                <div>
                    {hasBanner &&
                        <div className="ban">
                            <p className="p-4">OFFRE DE LANCEMENT : -20% à vie avec le code
                                &quot;<span className="font-bold">EARLY</span>&quot;</p>
                        </div>}
                    <Logo className="mt-10 mb-0 mx-auto"/>
                </div>
                <div className="flex-grow flex">
                    <Outlet/>
                </div>
            </main>
        </div>
    );
};

export default MainLayout;