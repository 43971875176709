import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import PricingPlans from '../pages/Onboarding/PricingPlans';
import PaymentForm from "../pages/Onboarding/PaymentForm";
import ConfirmPayment from "../pages/Onboarding/ConfirmPayment";
import ErrorPayment from "../pages/Onboarding/ErrorPayment";
import ValidEmail from "../pages/Onboarding/ValidEmail";
import ForgotPassword from "../pages/Signin/ForgotPassword";
import GuestRegistration from "../pages/Signin/GuestRegistration";
import Login from "../pages/Signin/Login";

const AppRoutes: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" index element={<Login />} />
                <Route path="/login" index element={<Login />} />

                <Route path="/" element={<MainLayout hasBanner/>}>
                    <Route index path="pricing-plans" element={<PricingPlans/>}/>
                    <Route path="payment-form" element={<PaymentForm/>}/>
                </Route>

                <Route path="/" element={<MainLayout/>}>
                    <Route path="confirm-payment" element={<ConfirmPayment/>}/>
                    <Route path="error-payment" element={<ErrorPayment/>}/>
                    <Route path="valid-email" element={<ValidEmail/>}/>

                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/guest-registration" element={<GuestRegistration />} />

                </Route>

            </Routes>
        </Router>
    );
};

export default AppRoutes;
