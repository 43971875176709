import React from 'react';
import {ReactComponent as Logo} from '../../../assets/images/logo.svg';
import WInput from "../../../components/WInput";
import WButton from "../../../components/Wbutton";
import "./styles.css";
import {CheckIcon, ChevronRightIcon} from "@heroicons/react/16/solid";
import {useNavigate} from "react-router-dom";

const LoginPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="flex h-screen">
            {/* Section de gauche - 300px */}
            <div className="w-[300px] flex flex-col items-center p-6">
                {/* Logo */}
                <div className="mb-6 mt-28">
                    <Logo className="w-52 h-auto"/>
                </div>

                {/* Formulaire de connexion */}
                <form className="w-full">
                    <div className="mb-4">
                        <WInput name="email" placeholder="E-mail"/>
                    </div>
                    <div className="mb-6">
                        <WInput name="email" type="password" placeholder="Mot de passe"/>
                    </div>

                    <WButton variant="secondary" className="w-full">Connexion</WButton>
                    <a href="/login" className="block text-green-600 text-sm font-medium underline mt-3">Mot de passe
                        oublié ?</a>
                </form>
            </div>

            {/* Section de droite - Reste de l'écran */}
            <div className="flex-1 bg-img bg-gray-100 flex flex-col items-center p-32">

                <div className="w-full max-w-screen-md login-desc-block text-left">

                    <h2 className="ml-8 text-3xl font-bold mb-6">Pas encore inscrit ?</h2>
                    <h3 className="ml-8 font-bold mb-6">Créez votre premier projet pour :</h3>

                    {/* Checkpoints */}
                    <ul className="list-none pl-0 font-medium text-base">
                        <li className="flex items-center">
                            <CheckIcon className="h-6 w-6 text-green-500 mr-2"/>
                            Optimiser votre DPE
                        </li>
                        <li className="flex items-center">
                            <CheckIcon className="h-6 w-6 text-green-500 mr-2"/>
                            Faire un plan d’action des travaux nécessaires
                        </li>
                        <li className="flex items-center">
                            <CheckIcon className="h-6 w-6 text-green-500 mr-2"/>
                            Évaluer le coût des travaux
                        </li>
                        <li className="flex items-center">
                            <CheckIcon className="h-6 w-6 text-green-500 mr-2"/>
                            Évaluer les économies d’énergies que vous allez réaliser
                        </li>
                    </ul>

                    <div className="flex flex-row mt-6 justify-center items-center">
                        <img src="/images/redG.png" alt="Checkmark" className="h-50 w-50 mr-2"/>
                        <span
                            className="border-b-4 border-dotted border-gray-600 relative w-[180px] left-[14px]">
                        </span>
                        <ChevronRightIcon className="w-10 mr-3"/>
                        <img src="/images/greenB.png" alt="Checkmark" className="h-50 w-50 mr-2"/>
                    </div>

                    <div className="flex flex-row mt-12 justify-center items-center">
                        <WButton variant="tertiary" onClick={() => { navigate('/pricing-plans') }} >Commencer ici</WButton>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default LoginPage;
