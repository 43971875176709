import React from 'react';
import "./styles.css";
import WInput from "../../../components/WInput";
import WButton from "../../../components/Wbutton";
import {useTranslation} from "react-i18next";

const ForgotPassword: React.FC = () => {

    const {t} = useTranslation();

    return (
        <div className="flex flex-col w-svw">
            <span className="font-bold text-lg mt-8">Mot de passe oublié ?</span>
            <span className="font-normal">
                Renseignez l’email de votre compte et nous vous enverrons un lien pour<br/>
                créer un nouveau mot de passe
            </span>

            <div className="flex flex-col form-container">
                {/* Password */}
                <div className="flex gap-6">
                    <WInput placeholder={t('ph-password')}
                            name="password"
                            type="password"
                            required/>
                </div>

                <div className="flex m-auto mt-8">
                    <WButton variant="secondary" className="btn-form">
                        Envoyer
                    </WButton>
                </div>
            </div>

            <div className="flex justify-center space-x-4 mt-3">
                <a href="/login" className="text-gray-400 text-sm font-medium underline mr-10">J’ai déjà un compte</a>
                <a href="/login" className="text-green-600 text-sm font-medium underline ml-10">Je me connecte</a>
            </div>

        </div>);
};

export default ForgotPassword;