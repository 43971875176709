import React from 'react';
import WButton from "../../../components/Wbutton";
import WInfo from "../../../components/WInfo";
import {useNavigate} from "react-router-dom";

const ConfirmPayment: React.FC = () => {

    const navigate = useNavigate();

    return (
        <div className="flex flex-col w-svw">

            <WInfo>Votre payement à été effectué avec succès. Merci !</WInfo>

            <span className="font-bold text-lg mt-3">Dernière étape : Vérifiez votre adresse e-mail</span>
            <span className="font-normal"> Cliquez sur le lien dans l’e-mail que nous vous avons envoyé.<br/>
                Vérifiez dans vos spams si vous n’avez pas reçu notre e-mail dans les 5 minutes.<br/>
                Renseignez notre adresse email dans vos contact pour que nos e-mails n’arrivent pas dans vos spams</span>

            <div className="flex gap-6 mt-16 m-auto flex-col">
                <WButton variant="secondary" onClick={() => navigate('/login')}>
                    Connectez-vous et créez votre premier projet
                </WButton>
                <WButton>
                    Renvoyer l’e-mail de confirmation
                </WButton>
            </div>

        </div>);
};

export default ConfirmPayment;