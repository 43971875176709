import React from 'react';
import './styles.css';
import {IWInfoBlockProps} from "./types";


const WInfo: React.FC<IWInfoBlockProps> = ({ children , variant = "info"}) => {
    return (
        <div className={`w-info-container ${variant === 'error' ? 'w-error' : 'w-info'}`}>
            {children}
        </div>
    );
};

export default WInfo;
