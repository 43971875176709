import React from 'react';
import WButton from "../../../components/Wbutton";
import WInfo from "../../../components/WInfo";

const ValidEmail: React.FC = () => {

    return (
        <div className="flex flex-col w-svw">

            <WInfo>Votre adresse e-mail est vérifiée. Merci !</WInfo>

            <span className="font-bold text-lg mt-3">C’est parti !</span>
            <span className="font-normal">Connectez-vous et créer votre premier projet</span>

            <div className="flex gap-6 mt-16 m-auto flex-col">
                <WButton variant="secondary">
                    Connectez-vous
                </WButton>
            </div>

        </div>);
};

export default ValidEmail;